import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import CustomTable, { CustomColumn } from "../../components/CustomTable";
import styles from "./index.module.scss";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Stack,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import CustomField from "../AdminForms/CustomField";
import CustomModal from "../AdminForms/CustomModal";
import { AppContext } from "../../common/AppProvider";
import CustomForm from "../AdminForms/CustomForm";
import {
  updateControlledClass,
  getsafissd,
  createControlledClasses,
} from "../../services/controltable";
import moment from "moment";
import Select, { Options } from "react-select";
import useSSIDData, { SAFI_ACTIONS } from "./useSSIDData";
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/fi";
import { table } from "console";
import { getEnabledCategories } from "trace_events";

type YearOption = { label: string; value: string };

export const SafiSSd = () => {
  const { data } = useContext(AppContext);

  const {
    tableData,
    loading,
    dispatch,
    dicts,
    control_name,
    control_value,
    description,
    created_at,
    updated_at,
    updated_by,
    enable_safi_control,
    enable_ssd,
    enable_summer_ssd,
    enable_summer_ssd_md,
  } = useSSIDData();

  const columns: Array<CustomColumn> = [
    {
      name: "effectiveDate",
      label: "Effective Date",
      cellClass: `${styles.uid1_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      width: 85
    },
    {
      name: "allowedCareers",
      label: "Allowed Careers",
      cellClass: `${styles.updateddate_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      width: 88
    },
    {
      name: "allowedRegTypes",
      label: "Allowed Reg Types",
      cellClass: `${styles.academicyear_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      width: 120
    },
    {
      name: "excludeMajors",
      label: "Exclude Majors",
      cellClass: `${styles.flagvalue_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      width: 90
    },
    {
      name: "allowSummerCourses",
      label: "Allow Summer Courses",
      cellClass: `${styles.updateddate_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      width: 120
    },
    // {
    //   name: "allowSSD",
    //   label: "Allow SSD",
    //   cellClass: `${styles.updatedby_col}`,
    //   showInExcel: true,
    //   width: 65,
    //   headerClass: `text-nowrap`,
    // },
    {
      name: "excludedCollegeIds",
      label: "Excluded College Ids",
      cellClass: `${styles.uid1_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      width: 110
    },
    {
      name: "excludedClassficationIds",
      label: "Excluded Classfication Ids",
      cellClass: `${styles.flagname_col}`,
      showInExcel: true,
      width: 130,
      headerClass: `text-nowrap`,
    },
    {
      name: "Actions",
      label: "Actions",
      headerClass: `text-center`,
      renderRow: (_, row, idx) => rowActions(row, idx),
      width: 70,
    },
  ];

  const ref = useRef();
  const currAcdYear = `${moment().format("YYYY")}-${moment()
    .add(1, "years")
    .format("YYYY")}`;

  const [showModal, setShowModal] = useState(null);
  const [modifiedDict, setModifiedDict] = useState(false);
  const intialdata = {
    effectiveDate: moment().format("YYYY-MM-DD"),
    allowedCareers: "D,M,U,L,G",
    allowedRegTypes: "NEW, APP, NIT, blank",
    // allowSSD: "Y",
    allowSummerCourses: "Y",
    excludedClassficationIds: "0246,0728",
    excludedCollegeIds: "0246,0728",
    excludeMajors: "0246,0728",
  };
  const [currData, setCurrData] = useState<any>({});
  const [currIndex, setCurrIndex] = useState(null);
  const [year, setYear] = useState<YearOption | null>({
    label: currAcdYear,
    value: currAcdYear,
  });
  //  const [safiEnabled, setSafiEnabled] = useState<string | null>(null);
  const [safiEnabled, setSafiEnabled] = useState(false);
  const [safiEnabledSsd, setSafiEnabledSsd] = useState(false);
  const [safiEnabledSummerSsd, setSafiEnabledSummerSsd] = useState(false);
  const [safiEnabledSummerSsdMd, setSafiEnabledSummerSsdMd] = useState(false);
  const [toast, setToast] = useState<null | string>(null);

  useEffect(() => {
    console.log("test ==> ", enable_safi_control);
    setTimeout(() => {
      setSafiEnabled(enable_safi_control === "Y");
      setSafiEnabledSummerSsdMd(enable_summer_ssd_md === "Y");
      setSafiEnabledSsd(enable_ssd === "Y");
      setSafiEnabledSummerSsd(enable_summer_ssd === "Y");
    }, 1000);
  }, [tableData]);

  console.log("safiEnabled ==>", safiEnabled);

  useEffect(() => {
    if (year) {
      dispatch({ type: SAFI_ACTIONS.FETCH_RULES, payload: year });
    }
  }, [year, dispatch]);

  const handleCurrValChange = (e) => {
    setCurrData((prev) => {
      prev[e.target.name] = e.target.value;
      return { ...prev };
    });
  };

  const onEditRow = (index, row) => {
    setCurrData((prev) => ({
      ...prev,
      ...row,
      effectiveDate: moment(row.effectiveDate).format("YYYY-MM-DD"),
    }));
    setCurrIndex(index);
    setShowModal("Edit");
  };

  // const onDeleteRow = (index) => {
  //   setFlagsData((prev) => {
  //     prev.splice(index, 1);
  //     return [...prev];
  //   });
  // };

  const handleAddRow = () => {
    setCurrData(() => ({
      ...intialdata,
    }));

    setShowModal("Add");
  };

  const rowActions = useCallback((row, index) => {
    return (  
      <Stack className="flex-row justify-content-center" gap={2}>
        <Button
          title="Edit Row"
          size="sm"
          onClick={() => onEditRow(index, row)}
          className="bg-transparent border-0"
          // disabled={moment(row.effectiveDate).isBefore(moment().format('MM-DD-YYYY'), 'day')}
        >
          <PencilIcon color="grey" width={18} />
        </Button>
        {/* {(row.isNewRow || row.modified) && (
          <Button
            title="Delete Row"
            size="sm"
            onClick={() => onDeleteRow(index)}
            className="bg-transparent border-0"
          >
            <TrashIcon color="grey" width={18} />
          </Button>
        )} */}
      </Stack>
    );
  }, []);

  //toDo: add all logic here to make update and add calls.
  const onModalClose = () => {
    setCurrData({ effectiveDate: moment().format("YYYY-MM-DD") });
    setCurrIndex(null);
    setShowModal(null);
  };

  const onModalSave = useCallback(async () => {
    if (showModal === "Edit") {
      if (currIndex === null) {
        throw new Error("Missing edited row index!");
      }
      setModifiedDict(true);
      dispatch({
        type: SAFI_ACTIONS.UPDATE_RULE,
        payload: { data: { ...currData }, index: currIndex },
      });
    } else {
      setShowModal(null);
      dispatch({
        type: SAFI_ACTIONS.ADD_RULE,
        payload: { ...currData },
        callBack: async (tableData, dicts) => {
          try {
            await createControlledClasses({
              updated_by: data?.login["custom:displayName"],
              dictionary: dicts,
              control_name: "SAFI_SSD_CONTROLS",
              control_value: year.label,
              description: "Dictionary for SAFI COntrols",
              created_at: moment().toISOString(),
              updated_at: moment().toISOString(),
              enable_safi_control: safiEnabled ? "Y" : "N",
              enable_ssd: safiEnabledSsd ? "Y" : "N",
              enable_summer_ssd: safiEnabledSummerSsd ? "Y" : "N",
              enable_summer_ssd_md: safiEnabledSummerSsdMd ? "Y" : "N",
            });
            if (safiEnabled) {
              setToast(`SAFI/SSD controls created successfully!`);
            }
          } catch (err) {
            setToast(`Flag disabled`);
          }
          dispatch({ type: SAFI_ACTIONS.FETCH_RULES, payload: year });
        },
      });
    }
    onModalClose();
  }, [currData, currIndex, showModal]);

  const academicYears: YearOption[] = [
    // `${moment().subtract(3, "years").format("YYYY")}-${moment()
    //   .subtract(2, "years")
    //   .format("YYYY")}`,
    // `${moment().subtract(2, "years").format("YYYY")}-${moment()
    //   .subtract(1, "years")
    //   .format("YYYY")}`,
    `${moment().subtract(1, "years").format("YYYY")}-${moment().format(
      "YYYY"
    )}`,
    `${moment().format("YYYY")}-${moment().add(1, "years").format("YYYY")}`,
    `${moment().add(1, "years").format("YYYY")}-${moment()
      .add(2, "years")
      .format("YYYY")}`,
  ].map((y) => ({
    value: y,
    label: y,
  }));

  const updateRules = async () => {
    try {
      await updateControlledClass({
        updated_by: data?.login["custom:displayName"],
        dictionary: dicts,
        updated_at: moment().toISOString(),
        control_name,
        control_value,
        description,
        created_at,
        enable_safi_control: safiEnabled ? "Y" : "N",
        enable_ssd: safiEnabledSsd ? "Y" : "N",
        enable_summer_ssd: safiEnabledSummerSsd ? "Y" : "N",
        enable_summer_ssd_md: safiEnabledSummerSsdMd ? "Y" : "N",
      });
      setModifiedDict(false);
      if (safiEnabled) {
        setToast(`SAFI/SSD controls saved successfully!`);
      }
    } catch (err) {
      setToast(`Flag disabled`);
    }
    dispatch({ type: SAFI_ACTIONS.FETCH_RULES, payload: year });
  };

  const academicYearDropDown = useMemo(() => {
    return (
      <div
        className="d-flex my-3 mx-1 align-items-center"
        style={{ gap: "10px", flex: 1 }}
      >
        <label
          className="text-muted"
          style={{
            fontSize: 12,
            fontWeight: 600,
            fontFamily: "Roboto, sans-serif",
          }}
        >
          Academic Year :
        </label>
        <Select
          isClearable
          onChange={setYear}
          name="year"
          options={academicYears as any}
          value={year}
          required
          placeholder="Select Academic Year"
        />
       <div className={styles.fieldWrapper}>
       <label
          className="text-muted"
          style={{
            fontSize: 12,
            fontWeight: 600,
            fontFamily: "Roboto, sans-serif",
          }}
        >
          Enable Safi Control?
        </label>
        <Checkbox
          checked={safiEnabled}
          icon={<Icon.FiCheck color="#174A41" size={20} />}
          style={{ cursor: "pointer" }}
          borderColor="#5b8cb2"
          onChange={setSafiEnabled}
        />
        <div className={styles.ml1}/>
       </div>
       <div className={styles.fieldWrapper}>
       <label
          className="text-muted"
          style={{
            fontSize: 12,
            fontWeight: 600,
            fontFamily: "Roboto, sans-serif",
          }}
        >
          Enable SSD?
        </label>
        <Checkbox
          checked={safiEnabledSsd}
          icon={<Icon.FiCheck color="#174A41" size={14} />}
          style={{ cursor: "pointer" }}
          borderColor="#5b8cb2"
          onChange={setSafiEnabledSsd}
        />
        <div className={styles.ml1}></div>
       </div>
       <div className={styles.fieldWrapper}>
        <label
          className="text-muted"
          style={{
            fontSize: 12,
            fontWeight: 600,
            fontFamily: "Roboto, sans-serif",
          }}
        >
          Enable Summer SSD?
        </label>
        <Checkbox
          checked={safiEnabledSummerSsd}
          icon={<Icon.FiCheck color="#174A41" size={14} />}
          style={{ cursor: "pointer" }}
          borderColor="#5b8cb2"
          onChange={setSafiEnabledSummerSsd}
        />
        <div className={styles.ml1}></div>
        </div>
         <div className={styles.fieldWrapper}>
        <label
          className="text-muted"
          style={{
            fontSize: 12,
            fontWeight: 600,
            fontFamily: "Roboto, sans-serif",
          }}
        >
          Enable Summer SSD for M&D?
        </label>
        <Checkbox
          checked={safiEnabledSummerSsdMd}
          icon={<Icon.FiCheck color="#174A41" size={14} />}
          style={{ cursor: "pointer" }}
          borderColor="#5b8cb2"
          onChange={setSafiEnabledSummerSsdMd}
        />
        {/* <div className={styles.ml1}></div> */}
    </div>
      </div>
    );
  }, [
    year,
    modifiedDict,
    safiEnabled,
    safiEnabledSsd,
    safiEnabledSummerSsd,
    safiEnabledSummerSsdMd,
  ]);

  return (
    <div className="safi-table relative">
      <Col className="relative">
        <Row>{academicYearDropDown}</Row>
        <Row>
          <CustomTable
            parentClass={styles.fullTableWidth as any}
            isLoading={loading}
            rows={tableData || []}
            columns={columns}
            bordered
            ref={ref}
            rowsPerPage={process.env.REACT_APP_ROWS_PAGE}
            rowClass="test"
            responsive
            striped
            title={null}
            style={{ tableLayout: "fixed" }}
            // pagination="default"
          />
        </Row>
      </Col>
      <div
        className="d-flex justify-content-end align-items-center"
        style={{ gap: "10px", flex: 1 }}
      >
        <div
          className="d-flex justify-content-start align-items-center text-muted"
          style={{ gap: "10px", flex: 1 }}
        >
          <label
            className="text-muted"
            style={{
              fontSize: 12,
              fontWeight: 600,
              fontFamily: "Roboto, sans-serif",
            }}
          >
            Last updated by:
          </label>
          <label
            className="text-muted"
            style={{ fontSize: 12, fontFamily: "Roboto, sans-serif" }}
          >
            {" "}
            {updated_by}{" "}
          </label>

          <label
            className="text-muted"
            style={{
              fontSize: 12,
              fontWeight: 600,
              fontFamily: "Roboto, sans-serif",
            }}
          >
            at:
          </label>
          <label
            className="text-muted"
            style={{ fontSize: 12, fontFamily: "Roboto, sans-serif" }}
          >
            {updated_at}
          </label>
        </div>
        <Button size="sm" onClick={handleAddRow} disabled={!year}>
          Add Rule
        </Button>
        {modifiedDict && (
          <Button size="sm" onClick={updateRules}>
            Save All Controls
          </Button>
        )}
        {!modifiedDict && (
          <Button size="sm" onClick={updateRules}>
            Save All Controls
          </Button>
        )}
      </div>
      <CustomModal
        title={`${showModal} Rule`}
        show={!!showModal}
        onClose={onModalClose}
        onSave={onModalSave}
        primaryBtnText={`${showModal === "Edit" ? "Update" : "Add"} Rule`}
      >
        <Form>
          <Stack className="mb-3" gap={3}>
            <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="Rule Effective Date"
              name="effectiveDate"
              type="Date"
              value={currData["effectiveDate"]}
              required
            />
            {/* <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="Allow SSD?"
              name="allowSSD"
              type="checkbox"
              hasDefault
              options={[
                { label: "Yes", value: "Y" },
                { label: "No", value: "N" },
              ]}
              value={currData["allowSSD"]}
              required
            /> */}

            <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="Allowed Careers"
              name="allowedCareers"
              type="text"
              value={currData["allowedCareers"]}
              required
            />
            <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="Allow Summer Courses?"
              name="allowSummerCourses"
              type="checkbox"
              hasDefault
              options={[
                { label: "Yes", value: "Y" },
                { label: "No", value: "N" },
              ]}
              value={currData["allowSummerCourses"]}
              required
            />
            <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="Allowed Reg Types"
              name="allowedRegTypes"
              type="text"
              value={currData["allowedRegTypes"]}
              required
            />
            <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="Excluded College Ids"
              name="excludedCollegeIds"
              type="text"
              value={currData["excludedCollegeIds"]}
              required
            />
            <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="ExcludedClassficationIds"
              name="excludedClassficationIds"
              type="text"
              value={currData["excludedClassficationIds"]}
              required
            />
            <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="Exclude Majors"
              name="excludeMajors"
              type="text"
              value={currData["excludeMajors"]}
              required
            />
          </Stack>
        </Form>
      </CustomModal>
      <div>
        <ToastContainer position="top-center">
          <Toast show={!!toast} onClose={() => setToast(null)}>
            <Toast.Header
              style={{ backgroundColor: "#274f82", color: "white" }}
            >
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Successful</strong>
              <small></small>
            </Toast.Header>
            <Toast.Body>{toast}</Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
    </div>
  );
};
